<template>
	<div class="tw-content-section tw-light-grey-bg-color p-3 position-relative" v-permission="{juniper: 'view_posts', supports: supportsTickerBar}">
		<div v-if="requestResolved">
			<h3 class="tw-content-section-title">Ticker Bar</h3>
			<div class="row">
				<div class="col-12 mb-3">
					<span>
						<div v-if="currentTicker" class="d-flex">
							<p class="w-100" v-html="currentTicker.content && currentTicker.content.rendered || currentTicker.content || ''"></p>
							<button class="btn tw-submit-btn tw-light-red-bg-color" @click="remove" :disabled="!userHasPermission('delete_posts')">Remove</button>
						</div>
						<div v-else class="d-flex">
							<input type="text" class="form-control me-3" v-model="content" placeholder="Ticker bar content">
							<button class="btn tw-submit-btn" @click="save" :disabled="!userHasPermission('create_posts')">Save</button>
						</div>
					</span>
				</div>
			</div>
		</div>
		<div v-else class="d-flex justify-content-center align-items-center">
			<i class="fa fa-spinner fa-pulse fa-4x border-0 tw-light-red-color"></i>
		</div>
	</div>
</template>

<script>
	import wordpressMixin from '@/mixins/wordpressMixin';
	import cms6Mixin from '@/mixins/cms6Mixin';
    import { permission } from '@/directives/permission';

	let site = localStorage.getObject('site');

	let mixins;

	if (site && site.type == 'wordpress') {
		mixins = [wordpressMixin];
	} else if(site && site.type == 'cms6') {
		mixins = [cms6Mixin];
	}

	export default {
		name: 'TickerBar',
		data: () => ({
			content: '',
			tickerCatId: null,
			post: {},
			supportsTickerBar: false,
			currentTicker: false,
			requestResolved: false
		}),
        directives: {
            permission,
        },
		async mounted () {
			this.prepareTickerBar();
		},
		methods: {
			save() {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to make new ticker notice?",
                    confirmButtonText: 'Yes!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						let title = 'Notice ' + this.moment().format("MM-DD-YYYY");
						let status = 'publish';
						let categories = [];
						categories.push(this.tickerCatId);
						this.post.title = title;
						this.post.status = status;
						this.post.categories = categories;
						this.post.content = this.content;
						try {

							return await this.savePost(this.post);

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then( (result) => {
					if (result.isConfirmed) {


						this.$swal(
							'Notice created!',
							"Ticker bar notice created successfully!",
							'success',
						)
						.then(() => {

							this.currentTicker = null;
							this.requestResolved = false;

							this.setCurrentTickerPost();
						});

					}
                });
			},
			remove() {
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to remove this ticker notice?",
                    confirmButtonText: 'Yes, remove it!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {

							return await this.deletePost(this.currentTicker.id);

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {
					if (result.isConfirmed) {


						this.$swal(
							'Notice removed!',
							"Ticker notice has been removed!",
							'success',
						)
						.then(() => {

							this.currentTicker = null;
							this.requestResolved = false;

							this.setCurrentTickerPost();
						});

					}
                });
            },
			async prepareTickerBar() {
				if (this.getCategories != undefined) {
					let resCat = await this.getCategories('ticker');

					if (resCat && resCat.data && resCat.data.length && resCat.data[0].id) {
						this.tickerCatId = await resCat.data[0].id;
						this.supportsTickerBar = true;

						await this.setCurrentTickerPost()
					}
					this.requestResolved = true;
				}
			},
			async setCurrentTickerPost() {
				let resPost = await this.getTicker( [ this.tickerCatId ] );

				if (resPost && resPost.status === 200 && resPost.data[0]) {
					this.currentTicker =  resPost.data[0];
				}
				this.requestResolved = true;
			}
		},
		mixins: mixins
	}
</script>

<style scoped>
	#tw-ticker-not-supported-overlay {
		background: rgba(248, 247, 247, .75);
		border-radius: 10px;
	}
	input, select {
		border-radius: 21.5px;
		height: 37px;
	}
	select {
		max-width: 150px;
		color: #CCD1D8;
		font-size: 14px;
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #CCD1D8 !important;
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: #CCD1D8 !important;
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: #CCD1D8 !important;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: #CCD1D8 !important;
	}
</style>
