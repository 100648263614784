<template>
    <div
        v-permission="{juniper: 'view_alerts', supports: alertSupported}"
        class="tw-content-section tw-create-alert-section tw-light-grey-bg-color p-3 col-12 d-flex flex-column position-relative"
        :class="[step && 'tw-create-alert-section-active', currentAlert && 'tw-create-alert-section-border']"
    >
        <h3 v-if="requestResolved" class="tw-content-section-title">Emergency Alert</h3>
        <div v-if="currentAlert" class="d-flex flex-column flex-grow-1">
            <label for="alert-type-test" class="w-100">
                <span class="tw-test-alert btn w-100 d-flex align-items-center justify-content-center text-uppercase tw-red-bg-color text-white cursor-default">
                    <i class="fas fa-bell me-2"></i> Real Alert
                </span>
            </label>
            <h4 class="mt-3">Delivery method</h4>
            <span>{{ currentAlertDeliveryMethods }}</span>
            <h4 class="mt-3">Contents of your alert</h4>
            <p>{{ currentAlert.content.raw || currentAlert.content }}</p>
            <h4 v-if="currentAlert.status === 'future'" class="alert alert-info mt-3">
                Scheduled: <span>{{ moment(currentAlert.date).format("LLL") }}</span>
            </h4>
            <div class="row mt-auto">
                <div class="col-12">
                    <button class="tw-create-alert-remove-btn w-100 btn tw-light-green-bg-color text-white" @click="removeAlert" :disabled="!userHasPermission('delete_alerts')">Remove Currently Online</button>
                </div>
            </div>
        </div>
        <div v-else-if="!currentAlert && !requestResolved" class="d-flex align-items-center justify-content-center tw-light-red-color flex-grow-1">
            <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
        </div>
        <div v-else class="d-flex flex-column flex-grow-1">
            <p class="tw-create-alert-warning-text text-center mt-5" v-if="(alert_type == 'alert' && step === 2) || (alert_type == 'test' && step === 1)">
                Are you sure you want to send this alert?
            </p>
            <h4 v-if="(alert_type == 'alert' && step !== 2) || (alert_type == 'test' && step !== 1) || (!alert_type && step === 0)" class="mt-3">Alert Type</h4>
            <div class="row">
                <div v-if="step === 2 && alert_type == 'alert' ? false : true" :class="step === 1 && alert_type == 'test' ? 'col-12' : 'col-md-6'">
                    <label for="alert-type-test" class="w-100">
                        <input type="radio" name="alert_type" id="alert-type-test" class="d-none alert-type" v-model="alert_type" value="test" :disabled="(step !== 0) || !userHasPermission('create_alerts')" />
                        <span class="tw-test-alert btn w-100 d-flex align-items-center justify-content-center text-uppercase text-nowrap">
                            <i class="fas fa-bell-slash me-2"></i> Test Alert
                        </span>
                    </label>
                </div>
                <div v-if="step !== 0 && alert_type == 'test' ? false : true" :class="step === 2 ? 'col-12' : 'col-md-6 mt-3 mt-md-0'">
                    <label for="alert-type-real" class="w-100">
                        <input type="radio" name="alert_type" id="alert-type-real" class="d-none alert-type" v-model="alert_type" value="alert" :disabled="(step !== 0) || !userHasPermission('create_alerts')" />
                        <span class="tw-test-alert btn w-100 d-flex align-items-center justify-content-center text-uppercase text-nowrap">
                            <i class="fas fa-bell me-2"></i> Real Alert
                        </span>
                    </label>
                </div>
            </div>
            <div v-if="alert_type == 'alert'">
                <h4 class="mt-3">Delivery method</h4>
                <Multiselect
                    v-model="delivery_method"
                    mode="tags"
                    class="tw-delivery-method-select"
                    :close-on-select="false"
                    :searchable="false"
                    :create-option="false"
                    :hideSelected="false"
                    :disabled="(step !== 0) || !userHasPermission('create_alerts')"
                    :options="[
                        { value: 'receive_email', label: 'Email' },
                        { value: 'receive_text_message', label: 'Text message' },
                        { value: 'website_ticker', label: 'Website Ticker' },
                    ]"
                />
            </div>
            <div v-if="alert_type == 'alert' && website_ticker">
                <h4 class="mt-3">Website ticker position</h4>
                <Multiselect
                    v-model="ticker_position"
                    mode="single"
                    class="tw-delivery-position-select"
                    :close-on-select="true"
                    :searchable="false"
                    :create-option="false"
                    :canClear="false"
                    :canDeselect="false"
                    :disabled="(step !== 0) || !userHasPermission('create_alerts')"
                    :options="[
                        { value: 'top', label: 'Top Ticker Bar' },
                        { value: 'bottom', label: 'Bottom Ticker Bar' },
                        { value: 'leftpopup', label: 'Left Corner Screen' },
                        { value: 'rightpopup', label: 'Right Corner Screen' },
                        { value: 'none', label: 'None' },
                    ]"
                />
            </div>
            <div class="row mt-3" v-if="alert_type == 'test'">
                <h4>Select recepient email and/or phone number:</h4>
                <div class="form-group col-sm-12">
                    <label for="post-category">Email</label>
                    <input type="email" required class="form-control tw-create-test-alert-email" v-model="test_email" id="post-category-email" :disabled="(step !== 0) || !userHasPermission('create_alerts')" />
                </div>
                <div class="form-group col-sm-12 mt-3">
                    <label for="post-category">Phone Number</label>
                    <input type="text" class="form-control tw-create-test-alert-sms" v-model="test_sms" id="post-category-phone" :disabled="(step !== 0) || !userHasPermission('create_alerts')" />
                </div>
            </div>
            <div class="mb-3">
                <h4 class="mt-3">Contents of your alert</h4>
                <textarea rows="4" class="form-control" v-model="content" :disabled="(step !== 0) || !userHasPermission('create_alerts')" required></textarea>
            </div>
            <div v-if="(step !== 0) && userHasPermission('create_alerts')" class="row mt-3">
                <div class="mb-3" v-if="schedule_popup">
                    <Datepicker class="tw-create-alert-schedule-time" v-model="date" :format="format" :disabled="(step === 2) || !userHasPermission('create_alerts')" weekStart="0" />
                </div>
                <div class="text-center" :class="alert_type == 'alert' ? 'col-md-4' : 'col-md-6'">
                    <button class="btn w-100 mb-3 mb-md-0 text-white" :class="alert_type == 'alert' && step === 1 ? 'tw-blue-bg-color' : 'tw-red-bg-color'" @click="handleSend" :disabled="!userHasPermission('create_alerts')">
                        {{ alert_type == "alert" && step === 1 ? "Confirm" : "Send" }}
                        <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                    </button>
                </div>
                <div v-if="alert_type == 'alert'" class="col-md-4 text-center">
                    <button class="btn w-100 mb-3 mb-md-0 tw-green-bg-color text-white" @click="schedule" :disabled="step === 2">Schedule</button>
                </div>
                <div class="text-center" :class="alert_type == 'alert' ? 'col-md-4' : 'col-md-6'">
                    <button class="btn w-100 mb-3 mb-md-0 tw-create-alert-cancel-btn" @click="prev">Cancel</button>
                </div>
            </div>
            <div v-else-if="(step === 0) && userHasPermission('create_alerts')" class="mt-auto">
                <button
                    class="tw-create-alert-next-btn btn tw-dark-grey-bg-color text-white w-100"
                    @click="next"
                    :disabled="
                        (step === 0 &&
                            ((alert_type === 'alert' && !receive_email && !receive_text_message && !website_ticker) ||
                                (alert_type === 'test' && test_email === '' && test_sms === ''))) ||
                        content === '' ||
                        !alert_type
                        || !userHasPermission('create_alerts')
                    "
                >
                    Next
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import wordpressMixin from "@/mixins/wordpressMixin";
import cms6Mixin from "@/mixins/cms6Mixin";
import siteMixin from "@/mixins/siteMixin";
import userMixin from "@/mixins/userMixin";

let site = localStorage.getObject("site");

let mixins;

if (site && site.type == "wordpress") {
    mixins = [wordpressMixin, siteMixin, userMixin];
} else if (site && site.type == "cms6") {
    mixins = [cms6Mixin, siteMixin, userMixin];
} else {
    mixins = [siteMixin, userMixin];
}
export default {
    name: "AlertCreate",
    components: {
        Multiselect,
    },
    data: () => ({
        spinner: false,
        alert: {},
        alert_type: null,
        receive_email: false,
        receive_text_message: false,
        website_ticker: false,
        ticker_position: "top",
        test_email: "",
        test_sms: "",
        schedule_popup: false,
        date: null,
        format: "LLL dd, hh:mm a",
        content: "",
        stats: false,
        step: 0,
        delivery_method: null,
        currentAlert: null,
        requestResolved: false,
    }),
    mounted() {
        this.date = this.moment().format("ll hh:mm a");
        if (this.alertSupported) {
            this.setCurrentAlert();
        } else {
            this.requestResolved = true;
        }
    },
    watch: {
        delivery_method(val) {
            if (val) {
                this.receive_email = val.includes("receive_email");
                this.receive_text_message = val.includes("receive_text_message");
                this.website_ticker = val.includes("website_ticker");
            } else {
                this.receive_email = false;
                this.receive_text_message = false;
                this.website_ticker = false;
            }
        },
        alert_type() {
            this.schedule_popup = false;
        },
        step(val) {
            if (val) {
                this.$store.commit("showOverlay");
            } else {
                this.$store.commit("hideOverlay");
            }
        },
    },
    methods: {
        async setCurrentAlert() {
            let page = 1;
            let perPage = 1;
            let res = await this.getAlerts(page, perPage);
            if (res && res.status === 200 && res.data[0]) {
                this.currentAlert = res.data[0];
            }
            this.requestResolved = true;
        },
        handleSend() {
            if (this.step === 1 && this.alert_type == "alert") {
                this.step = 2;
            } else {
                this.storeNewAlert();
            }
        },
        async storeNewAlert() {
            this.spinner = true;

            this.alert.alert_type = this.alert_type;
            this.alert.content = this.content;
            this.alert.status = this.alert_type == "test" ? "private" : "publish";
            this.alert.test_email = btoa(this.test_email);
            this.alert.test_sms = btoa(unescape(encodeURIComponent(this.test_sms.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""))));
            this.alert.ticker_position = this.ticker_position;
            this.alert.title = "Emergency Alert " + this.moment().format("MM-DD-YYYY");

            if (this.website_ticker) {
                this.alert.website_ticker = (this.siteType == 'cms6') ? true : 'on';
            }

            if (this.receive_email) {
                this.alert.receive_email = (this.siteType == 'cms6') ? true : 'on';
            }

            if (this.receive_text_message) {
                this.alert.receive_text_message = (this.siteType == 'cms6') ? true : 'on';
            }

            if (this.schedule_popup) {
                this.alert.date = this.date;
            }

            let res;

            if (this.editingAlertId) {
                res = await this.updateAlert(this.alert);
            } else {
                res = await this.saveAlert(this.alert);
            }

            this.spinner = false;

            if (res.status === 201 || res.status === 200) {
                this.$swal("Alert created!", "New alert created successfully.", "success").then(() => {
                    this.alert_type = null;
                    this.step = 0;
                    this.content = "";
                    this.delivery_method = null;
                    this.requestResolved = false;
                    this.setCurrentAlert();
                });
            } else {
                this.$swal("Oh Snap!", "Server error!", "error");
            }
        },
        removeAlert() {
            this.$swal({
                title: "Are you sure?",
                showCancelButton: true,
                icon: "warning",
                text: "Are you sure you want to remove this alert?",
                confirmButtonText: "Yes, remove it!",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        return await this.deleteAlert(this.currentAlert.id);
                    } catch (error) {
                        this.$swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$swal("Alert removed!", "Alert has been removed!", "success").then(() => {
                        this.currentAlert = null;
                        this.requestResolved = false;

                        this.setCurrentAlert();
                    });
                }
            });
        },
        next() {
            this.step++;
        },
        prev() {
            this.step--;
        },
        goTo(step) {
            this.step = step;
        },
        schedule() {
            this.schedule_popup = !this.schedule_popup;
        },
    },
    computed: {
        currentAlertDeliveryMethods() {
            let deliveryMethods = [];

            if (this.currentAlert.receive_email != "") {
                deliveryMethods.push("Email");
            }

            if (this.currentAlert.receive_text_message != "") {
                deliveryMethods.push("Text Message");
            }

            if (this.currentAlert.website_ticker != "") {
                deliveryMethods.push("Website Ticker");
            }

            return deliveryMethods.join(", ");
        },
        alertSupported() {
            return this.supportsAlerts();
        },
        siteType() {
            return this.getSiteType();
        },
    },
    mixins: mixins,
};
</script>
<style scoped>
#tw-alert-not-supported-overlay {
    background: rgba(248, 247, 247, 0.75);
    border-radius: 10px;
}
.tw-create-alert-section {
    z-index: 6;
}
.tw-create-alert-section.tw-create-alert-section-active {
    border: 2px solid #d2233b;
    box-shadow: 0px 4px 24px rgba(222, 34, 47, 0.3);
}
.tw-create-alert-section-border {
    border: 2px solid #d2233b;
    box-shadow: 0px 4px 24px rgba(222, 34, 47, 0.3);
}
h4 {
    font-size: 19px;
    font-weight: 400;
}
label span.btn {
    border-radius: 4px;
    height: 61px;
    font-size: 20px;
    background: #fff;
    color: #56687e;
    border: 2px solid rgba(194, 194, 194, 0.4);
}
label input:checked + span.btn {
    background: var(--tw-red);
    border-color: var(--tw-red);
    color: #fff;
}
textarea,
.tw-delivery-position-select,
.tw-create-alert-schedule-time input,
.tw-create-test-alert-email,
.tw-create-test-alert-sms,
.tw-delivery-method-select {
    border-radius: 22px;
    border: 0;
}
button {
    border-radius: 21.5px;
}
button:not(.tw-create-alert-next-btn):not(.tw-create-alert-remove-btn) {
    max-width: 143px;
}
.multiselect.is-active {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    border-color: #86b7fe;
}
.tw-create-alert-cancel-btn {
    color: #828282;
    background: #e0e0e0;
}
.tw-create-alert-warning-text {
    font-size: 18px;
    font-weight: 700;
    color: var(--tw-default-font-color);
}
</style>
