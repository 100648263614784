<template>
	<div class="row">
		<div class="col-sm-6 col-lg-3 mb-3 mb-lg-0" v-permission="{juniper: 'create_news_notices', wp: 'notices', tooltip: true }">
			<router-link class="tw-quick-link-btn position-relative btn w-100 tw-red-bg-color text-white text-start d-flex align-items-center ps-5" to="/news-notices#create">
				<span class="position-absolute d-flex justify-content-center align-items-center tw-light-red-bg-color rounded-circle"><i class="fas fa-plus"></i></span> <span class="ps-xl-3 d-flex align-items-center flex-grow-1">News/Notices</span>
			</router-link>
		</div>
		<div class="col-sm-6 col-lg-3 mb-3 mb-lg-0" v-permission="{juniper: 'create_events', wp: 'events', tooltip: true }">
			<router-link class="tw-quick-link-btn position-relative btn w-100 tw-red-bg-color text-white text-start d-flex align-items-center ps-5" to="/events#create">
				<span class="position-absolute d-flex justify-content-center align-items-center tw-light-red-bg-color rounded-circle"><i class="fas fa-plus"></i></span> <span class="ps-xl-3 d-flex align-items-center flex-grow-1">Create Event</span>
			</router-link>
		</div>
		<div class="col-sm-6 col-lg-3 mb-3 mb-sm-0" v-permission="{juniper: 'create_meeting_repository', wp: 'agendas', tooltip: true }">
			<router-link class="tw-quick-link-btn position-relative btn w-100 tw-red-bg-color text-white text-start d-flex align-items-center ps-5" to="/meeting-repository#create">
				<span class="position-absolute d-flex justify-content-center align-items-center tw-light-red-bg-color rounded-circle"><i class="fas fa-plus"></i></span> <span class="ps-xl-3 d-flex align-items-center flex-grow-1">Minutes/Agendas</span>
			</router-link>
		</div>
		<div class="col-sm-6 col-lg-3" v-permission="{juniper: 'view_subscribers', tooltip: true }">
			<router-link class="tw-quick-link-btn position-relative btn w-100 tw-red-bg-color text-white text-start d-flex align-items-center ps-5" to="/subscribers">
			<!-- <router-link class="tw-quick-link-btn position-relative btn w-100 tw-red-bg-color text-white text-start d-flex align-items-center ps-5" to="/subscribers#create"> -->
				<span class="position-absolute d-flex justify-content-center align-items-center tw-light-red-bg-color rounded-circle"><i class="fas fa-plus"></i></span> <span class="ps-xl-3 d-flex align-items-center flex-grow-1">Subscribers</span>
			</router-link>
		</div>
		<!-- <div v-if="permissions && !permissions.pages" class="col-sm-6 col-lg-3">
			<router-link class="tw-quick-link-btn position-relative btn w-100 tw-red-bg-color text-white text-start d-flex align-items-center ps-5" to="/pages#create">
				<span class="position-absolute d-flex justify-content-center align-items-center tw-light-red-bg-color rounded-circle"><i class="fas fa-plus"></i></span> <span class="ps-xl-3 d-flex align-items-center flex-grow-1">Existing Page</span>
			</router-link>
		</div> -->
	</div>
</template>

<script>
	import userMixin from '@/mixins/userMixin';
	export default {
		name: 'QuickLinks',
		data: () => ({
			permissions: null,
		}),
        mounted() {
			this.permissions = this.getCurrentUserPermissions();
        },
        mixins: [userMixin],
	}
</script>
