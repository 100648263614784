<!-- Modal -->
<template>
    <div class="modal fade" id="supportModal" ref="supportModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="row">
                        <h5 class="modal-title col-12" id="modal-title">Create a support ticket</h5>
                        <p class="col-12">Talk with us</p>
                    </div>
                </div>

                <form @submit.prevent="send">
                    <div class="modal-body" id="modal-body">
                        <div v-show="sendError" class="alert alert-danger" role="alert">Error: Support ticket wasn't sent.</div>
                        <div class="form-group mb-3">
                            <input type="text" class="form-control" v-model="subject" required id="ticket-subject" placeholder="Subject">
                        </div>

                        <div class="form-group">
                            <textarea rows="10" id="ticket-message" class="form-control" v-model="message" required placeholder="Describe your problem or request"></textarea>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn tw-red-bg-color text-white">
                            Send
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                        <button class="btn tw-dark-grey-bg-color text-white" type="button" @click="modal.hide()">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import { Modal } from 'bootstrap';
    import wordpressMixin from '@/mixins/wordpressMixin';
    import cms6Mixin from "@/mixins/cms6Mixin";


    let site = localStorage.getObject("site");

    if (!site) {
        site = { type: "wordpress"};
    }

    let mixins;

    if (site.type == "wordpress") {
        mixins = [wordpressMixin];
    } else if (site.type == "cms6") {
        mixins = [cms6Mixin];
    }


    export default {
        name: 'SupportModal',
        data: () => ({
            modal: null,
            toast: null,
            subject: '',
            message: '',
            spinner: false,
            sendError: false,
        }),
        mounted() {
            this.modal = new Modal(this.$refs.supportModal)
            // this.showToast()
            let supportModal = document.getElementById('supportModal')
            let self = this;
            supportModal.addEventListener('hidden.bs.modal', function () {
                // self.$emit('modalHidden');
                self.subject = '';
                self.message = '';
            });

        },
        methods: {
            showModal: function(value) {
                if (value) {
                    this.modal.show();
                }
            },
            // showToast: function() {
            //     this.$bvToast.toast(`This is toast number 55`, {
            //       title: 'BootstrapVue Toast',
            //       autoHideDelay: 5000,
            //       appendToast: true
            //     })
            // },
            send: async function() {
                this.spinner = true;
                let ticket = await this.sendTicket(this.subject, this.message);
                this.sendError = !ticket.data.sent;
                this.spinner = false;
                this.modal.hide();
                if (ticket.data.sent) {
                    this.$swal({
                        title: 'Support ticket sent!',
                        toast: true,
                        position: 'bottom-end',
                        showCloseButton: true,
                        timer: 5000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        icon: 'success',
                    });
                    // this.$store.commit('showToast', {title: "Support ticket sent!", message: "", color: "success"});
                } else {
                    this.$swal({
                        title: 'Oh Snap!',
                        toast: true,
                        position: 'bottom-end',
                        showCloseButton: true,
                        timer: 5000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        icon: 'error',
                        text: "Server error!",
                    });
                    // this.$store.commit('showToast', {title: "Oh Snap", message: "Server error", color: "danger"});
                }
            }
        },
        mixins: mixins
    }
</script>
<style scoped>
    input, textarea {
        border-radius: 21.5px;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #CCD1D8 !important;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: #CCD1D8 !important;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: #CCD1D8 !important;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: #CCD1D8 !important;
    }
</style>
