<template>
	<section id="tw-view-home" class="tw-content col-12">
		<div class="bg-white">
			<div class="p-4">
				<QuickLinks />
				<div class="row mt-3">
					<div class="tw-home-left-section col-lg-9 mb-3 mb-lg-0">
						<TickerBar />
						<div class="row mt-3">
							<div class="col-md-6 d-flex mb-3 mb-md-0">
								<AlertCreate />
							</div>
							<div class="col-md-6 d-flex">
								<Support />
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-md-6 d-flex mb-3 mb-md-0">
								<ButtonLink title="Book a training" url="https://www.townweb.com/training" description="Need help, sign up for training." />
							</div>
							<div class="col-md-6 d-flex">
								<ButtonLink title="Help articles & videos" url="https://help.townweb.com" description="Looking for something, check our help articles." />
							</div>
						</div>
					</div>
					<div class="tw-home-right-section col-lg-3 d-flex">
						<Subscription />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import QuickLinks from '@/components/home-components/QuickLinks';
	import TickerBar from '@/components/home-components/TickerBar';
	import AlertCreate from '@/components/home-components/AlertCreate';
	import Support from '@/components/home-components/Support';
	import ButtonLink from '@/components/home-components/ButtonLink';
	// import MediaLibrary from '@/components/home-components/MediaLibrary';
	import Subscription from '@/components/home-components/Subscription';
	export default {
		name: 'Home',
		components: {
			QuickLinks,
			TickerBar,
			AlertCreate,
			Support,
			ButtonLink,
			// MediaLibrary,
			Subscription,
		},
		data: () => ({

		}),
		mounted() {

		},
		methods: {

		},
		mixins: []
	}
</script>

<style scoped>
	@media only screen and (min-width: 992px) {
		.tw-home-left-section {
			width: 70% !important;
		}
		.tw-home-right-section {
			width: 30% !important;
		}
	}
</style>