<template>
	<div class="tw-content-section tw-light-grey-bg-color p-3 col-12 d-flex align-items-center justify-content-center flex-column">
		<a class="mt-5" :href="url" target="_blank"><button class="tw-red-bg-color text-white btn mb-3">{{ title }}</button></a>
		<p v-html="description"></p>
	</div>
</template>

<script>
	export default {
		name: 'ButtonLink',
		components: {
			
		},
		data: () => ({

		}),
		methods: {
			
		},
		props: {
			title: String,
			url: String,
			description: String,
		}
	}
</script>

<style scoped>
	p {
		font-size: 14px;
		margin-bottom: 9px;
	}
	button {
		font-size: 20px;
		min-width: 300px;
		height: 43px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	}
</style>