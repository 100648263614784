<template>
	<div class="tw-content-section tw-light-grey-bg-color p-3 col-12" v-permission="{juniper: 'send_dashboard_support_ticket'}">
		<h3 class="tw-content-section-title">You get FREE support from Town Web!</h3>
		<p>As a government-focused technology company we will always have your back! Consider our support team your website Virtual Assistants!</p>
		<div class="row">
			<div class="col-6">
				<div class="mb-5 pb-5">
					<p><span>Email: </span> <a class="tw-dark-grey-color text-decoration-none fw-bold" href="mailto:support@townweb.com">support@townweb.com</a></p>
					<p>Email Support 24/7/365</p>
					<p>Toll Free: <span class="fw-bold">877-995-TOWN (8696)</span></p>
					<p>Monday - Friday: 7am - 4pm CST</p>
				</div>
				<div>
					<button class="tw-red-bg-color text-white btn mb-3" @click="showModal">Support Ticket</button>
					<p>Submit a Support ticket.</p>
				</div>
			</div>
			<div class="col-6">
				<img class="w-100" alt="Support" src="@/assets/images/support.png">
			</div>
		</div>
	</div>
	<SupportModal ref="SupportModal" />
	<!-- <SupportModal ref="SupportModal" @showToast="showPushNotification"/> -->
</template>

<script>
	import SupportModal from '@/components/modals/SupportModal'
	export default {
		name: 'Support',
		components: {
			SupportModal,
		},
		data: () => ({

		}),
		methods: {
			showModal() {
				this.$refs.SupportModal.showModal(true);
			},
		}
	}
</script>

<style scoped>
	.col-md-6 > div > p {
		font-size: 14px;
		margin-bottom: 9px;
	}
	img {
		max-width: 206px;
	}
	button {
		font-size: 20px;
		width: 181px;
		height: 43px;
	}
</style>
